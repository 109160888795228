import { AppEnvironmentConfig } from 'src/app/pem-shared/models/environment-config.model';

/**
 * App environment config for UAT
 */
export const environment: AppEnvironmentConfig = {
  production: false,
  PRODUCT_NAME: 'HGCRM',
  API: 'https://api.exp-uat.mercuryhealthcare.com/api',
  features: {
    gainsight: false,
  },
  aptrinsic: {
    url: 'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
    tagId: 'AP-IXJ8CN4RT9P8-2',
  },
  AUTH0: {
    domain: 'auth0.mercuryhealthcare.com',
    tenant: 'healthgrades',
    clientId: 'EvMHER2g25q8V010INyRzAlHMuLhx4fH',
    consoleConnection: 'Preprodconsole', // TODO : Need to update once we have UAT Console connection
  },
  SESSION: {
    refreshSessionInterval: 600, // Refresh API sent every 10 minutes.
    logoutNotifyExpiryTime: 30, // 30 secs; Notification waits this time for a user action. If no-action, we log the user off
    maxIdleWaitTime: 900, // (In Seconds) 15 minutes; When there is no events from a user, we show a notification.
  },
  LOCAL_STORAGE_ENCRYPTION: 'mM0rKjdQwzWIXCw', // RANDOM TEXT USED TO ENCRYPT LOCAL STORAGE VALUES
  USER_SETTINGS: {
    mercuryConsoleUrl: 'https://gateway.exp-uat.mercuryhealthcare.com', // URL for Ignite Console
    helpCenterUrl: 'https://support.webmdignite.com/s/', // URL for Help Center
    dXeEnabledDate: '2023/09/13', //Date for helpCenter Sunset and DXE helpCenter ENabled
    dXeHelpURL: '/samlp/MMbKxAJHv3ySgyuCRnI4jNxCgMIMZgHr', //DXE URL
  },
  ELOQUA_CLIENT_ID: 'DjUXHrWt7Dz0iknhi4KPosDD2yR9Iu09',
  SAML_PROVIDER: 'samlp',
  HG_ENVIRONMENT: 'preprod', // environment to be passed to Eloqua SSO link against query param hg-environment
  SFHC_LOGIN_URL: 'https://test.salesforce.com/',
  SFMC_LOGIN_URL: 'https://mc.exacttarget.com/cloud/',
  ENABLE_LKP: true,
  TABLEAU_URL: 'https://tableau-exp-dev.eng.mercuryhealthcare.com/',
  TABLEAU_JS_API_VERSION: '2.9.1',
  SYNC_CONTACTS_POLL_INTERVAL: 30000, // Poll interval is set to 30 Seconds
  CONTACT_LIST_VERSION: 'v0_2',
  FILE_UPLOAD_POLL_INTERVAL: 60000, // 15 second poll to check file upload status,
  TACTICS_CAPTURE_SCRIPT_URL: 'https://script-app.mercuryhealthcare.com/hgcrm-utm-v1.0.js',
  GOOGLE_MAP_API_KEY: 'AIzaSyAyQ-fzOXchOfVg9etjF-9r8P4dXuxpvMg',
  GOOGLE_MAP_API_CLIENT_CODE: 'gme-cpm',
  GOOGLE_MAP_API_CHANNEL_ID: 'pes-webapp',
  SEGMENT_AUTO_SAVE_INTERVAL: 120000,
  ENABLE_CORP_HIERARCHY: true,
  ENABLE_SFHC_INTEGRATION: true,
  RISK_MODEL_HELP_CENTER:'https://apphelp.webmdignite.com/best-practices#:~:text=Avoid%20adding%20multiple%20risk%20models',
  RISK_MODEL_INFO: 'https://apphelp.webmdignite.com/audience-insights/reference/risk-model-selector',
  APPLICABLE_CRITERIA_HELP_CENTER: 'https://apphelp.webmdignite.com/audience-insights/reference/applicable-criteria-audience-type',
  ENABLE_DIGITAL_PERFORMANCE: false,
};
