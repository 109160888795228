/**
 * This enum is used for referring sub navigation other than the primary navigation.
 */
export enum SubNavigationEnum {
  TEMPLATE_LIST = 'template-list',
  CAMPAIGN = 'campaign',
  MY_CAMPAIGN = 'my-campaigns',
  ALL_CAMPAIGN = 'all-campaigns',
  FAVORITE_CAMPAIGN = 'favorite-campaigns',
  OVERVIEW = 'overview',
  EDIT_AUDIENCE = 'edit-audience',
  EDIT_TACTICS = 'edit-tactics',
  COST_SCHEDULE = 'cost-schedule',
  CAMPAIGN_REPORTING = 'campaign-reporting',
  DIGITAL_PERFORMANCE = 'digital-performance',
  LOOKUP_SEARCH = 'search',
  CONSUMER_PROFILE = 'consumer-profile',
  INTEGRATIONS = 'integrations',
  FILE_CONFIGURATIONS = 'file-configurations',
  FILE_UPLOADS = 'file-uploads',
  EDIT_TEMPLATE = 'edit-template',
}
